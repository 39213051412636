import React from "react";
import moment from "moment";

const OwnText = ({ text, time }) => {
  return (
    <div className="own-text">
      <div className="o-chats">
        <span>{text}</span>
        <span style={{ fontSize: "11px" }}>
          <i className="fa-solid fa-clock"></i> {moment(time).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default OwnText;

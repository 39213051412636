import moment from "moment";
import React from "react";

const FriendText = ({ text, time, userId, creator, participant }) => {
  return (
    <div className="ms-1 frend-text">
      <div className="f-img">
        <img
          style={{ objectFit: "cover" }}
          src={
            userId === creator.id
              ? participant.image
              : creator.image || "/assests/images/user1.png"
          }
          alt=""
        />
      </div>
      <div className="f-extra">
        <div className="f-text">
          <span>{text}</span>

          <span>
            <i className="fa-solid fa-clock"></i> {moment(time).fromNow()}
          </span>
        </div>
        <span className="f-names">
          {userId === creator.id ? participant.userName : creator.userName}
        </span>
      </div>
    </div>
  );
};

export default FriendText;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
// import TextsmsIcon from "@mui/icons-material/Textsms";
import Person3Icon from "@mui/icons-material/Person3";
import SettingsIcon from "@mui/icons-material/Settings";
// import LanguageIcon from "@mui/icons-material/Language";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { Highlight } from "@chakra-ui/react";
// import CastConnectedIcon from "@mui/icons-material/CastConnected";
// import AddLocationIcon from "@mui/icons-material/AddLocation";
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import FriendText from "../components/FriendText";
import OwnText from "../components/OwnText";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";

import { Fetch_Conversation, Fetch_Users, Log_Out } from "../store/actions";
import api from "../store/api";
import UserModal from "../components/UserModal";
import InputEmoji from "react-input-emoji";
import ProfileModal from "../components/ProfileModal";
const ChatPage = () => {
  const [conId, setConid] = useState();
  const [textMessage, setTextMessage] = useState();
  const [socketMessage, setSocketMessage] = useState();
  const [fetchmessage, setFetchMessage] = useState([]);
  const [fetchCreator, setFetchCreator] = useState({});
  const [fetchParticipant, setFetchParticipant] = useState({});

  const [show, setShow] = useState(false);
  const [profileShow, setProfileShow] = useState(false);
  const [open, setIsOpen] = useState(false);
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { conversation } = useSelector((state) => state.con);
  const socket = io(process.env.REACT_APP_SERVER_URL);

  useEffect(() => {
    socket.on("message", (data) => {
      if (data.action === "create") {
        const { message } = data;

        setSocketMessage(message);
      }
    });
  }, []);

  useEffect(() => {
    if (socketMessage !== undefined) {
      setFetchMessage([...fetchmessage, socketMessage]);
    }
  }, [socketMessage]);

  useEffect(() => {
    dispatch(Fetch_Conversation());
  }, [dispatch]);

  const onFetchMessage = async (id) => {
    const { data } = await api.get(`/api/message/${id}`, {
      headers: {
        Authorization: "Bearer " + user?.token,
      },
    });
    setFetchMessage(data.message);
    setFetchCreator(data.creator);
    setFetchParticipant(data.participant);
    // dispatch(Fetch_Message(id));
  };

  const onSubmitHandler = async () => {
    const recData = {
      conId: conId,
      id: fetchParticipant.id,
      userName: fetchParticipant.userName,
      email: fetchParticipant.email,
      text: textMessage,
    };

    await api.post(`/api/message/create`, recData, {
      headers: {
        Authorization: "Bearer " + user?.token,
      },
    });
    // setFetchMessage([...fetchmessage, data]);

    //dispatch(Create_Message(recData));
    setTextMessage("");
  };

  const handleShow = () => {
    setShow(true);
    dispatch(Fetch_Users());
  };

  const onClickHandler = () => {
    dispatch(Log_Out());
    localStorage.removeItem("users");
  };
  const handleClose = () => {
    setShow(false);
    setProfileShow(false);
  };

  const myaccountHandler = () => {
    setProfileShow(true);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [fetchmessage]);

  return (
    <>
      <div className="chat ">
        <div className="c-left">
          <i style={{ marginTop: "2rem" }}>
            <Menu>
              <MenuButton as={Button} colorScheme="white">
                <img
                  style={{ objectFit: "cover" }}
                  src={user.image ? user.image : "/assests/images/user1.png"}
                  alt=""
                />
              </MenuButton>
              <MenuList>
                <MenuItem className="text-dark fw-bold">
                  {user?.userName}
                </MenuItem>

                <MenuItem
                  onClick={onClickHandler}
                  className="text-danger fw-bold"
                >
                  LogOut
                </MenuItem>
              </MenuList>
            </Menu>
          </i>
          <i onClick={myaccountHandler}>
            <SettingsIcon style={{ fontSize: "27px" }} />
          </i>
          {/* <i>
            <TextsmsIcon style={{ fontSize: "27px" }} />
          </i> */}
          <i onClick={handleShow}>
            <Person3Icon style={{ fontSize: "32px" }} />
          </i>

          {/* 
          <i>
            <CastConnectedIcon style={{ fontSize: "27px" }} />
          </i>
          <i>
            <AddLocationIcon style={{ fontSize: "27px" }} />
          </i>
          <i>
            <WbSunnyIcon style={{ fontSize: "27px" }} />
          </i>
          <i>
            <LanguageIcon style={{ fontSize: "27px" }} />
          </i> */}
          <i style={{ marginTop: "-5px" }}>
            <Menu>
              <MenuButton as={Button} colorScheme="white">
                <MoreHorizIcon style={{ fontSize: "27px" }} />
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={onClickHandler}
                  className="text-danger fw-bold"
                >
                  LogOut
                </MenuItem>
              </MenuList>
            </Menu>
          </i>
        </div>

        <div className="c-middle">
          <div className="text">
            <h4
              style={{ fontSize: "18px", marginLeft: "-20px" }}
              className="text-white mt-2"
            >
              Conversation
            </h4>
          </div>
          <div className="text-input">
            <div className="inputs">
              <i className="fa-sharp fa-solid fa-magnifying-glass"></i>
              <input type="text" placeholder="Search messages or users" />
            </div>
          </div>
          {/* for mobile application */}
          <div className="text-round-icon">
            <ul>
              {conversation.map((item) => (
                <div onClick={() => setConid(item._id)} key={item._id}>
                  <div onClick={() => setIsOpen(true)}>
                    <div
                      onClick={() => onFetchMessage(item._id)}
                      className="d-flex gap-3"
                    >
                      {user.id === item.creator.id ? (
                        <>
                          <li>
                            <div className="extra">
                              <img
                                className="images"
                                style={{ objectFit: "cover" }}
                                src={
                                  item.participant.image
                                    ? item.participant.image
                                    : "/assests/images/user1.png"
                                }
                                alt=""
                              />
                              <div className="circle"></div>
                            </div>

                            <h3
                              style={{
                                fontSize: "16px",
                                fontFamily: "initial",
                                marginTop: "3px",
                              }}
                            >
                              {item.participant.userName}
                            </h3>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <div className="extra">
                              <img
                                className="images"
                                style={{ objectFit: "cover" }}
                                src={
                                  item.creator.image
                                    ? item.creator.image
                                    : "/assests/images/user1.png"
                                }
                                alt=""
                              />
                              <div className="circle"></div>
                            </div>

                            <h3
                              style={{
                                fontSize: "16px",
                                fontFamily: "initial",
                                marginTop: "3px",
                              }}
                            >
                              {item.creator.userName}
                            </h3>
                          </li>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </ul>
          </div>
          {/* for mobile application */}
          <div className="text-chat mt-4">
            {conversation.length > 0 && (
              <div className="banner">
                <h2 className="text-white">Conversation</h2>
              </div>
            )}
            <div className="chat-list">
              {/* start */}
              {conversation.length === 0 && (
                <Highlight
                  query="Please add user to start conversation"
                  styles={{
                    px: "4",
                    py: "2",
                    rounded: "full",
                    bg: "red.100",
                    marginStart: "3rem",
                  }}
                >
                  Please add user to start conversation
                </Highlight>
              )}
              {conversation.length > 0 && (
                <>
                  {" "}
                  {conversation.map((item) => (
                    <div onClick={() => setConid(item._id)} key={item._id}>
                      <div onClick={() => setIsOpen(true)}>
                        <div onClick={() => onFetchMessage(item._id)}>
                          {user.id === item.creator.id ? (
                            <>
                              {" "}
                              <div tabIndex="1" className="chat-items">
                                <div className="chat-list-left">
                                  <img
                                    style={{ objectFit: "cover" }}
                                    src={
                                      item.participant.image
                                        ? item.participant.image
                                        : "/assests/images/user1.png"
                                    }
                                    alt=""
                                  />
                                  <div>
                                    <h3 style={{ fontSize: "16px" }}>
                                      {item.participant.userName}
                                    </h3>
                                    <p className="small">click here to open</p>
                                  </div>
                                </div>
                                <div className="chat-list-right">
                                  <p className="small"></p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div tabIndex="1" className="chat-items">
                                <div className="chat-list-left">
                                  <img
                                    src={
                                      item.creator.image
                                        ? item.creator.image
                                        : "/assests/images/user1.png"
                                    }
                                    alt=""
                                  />
                                  <div>
                                    <h3 style={{ fontSize: "16px" }}>
                                      {item.creator.userName}
                                    </h3>
                                    <p className="small">click here to open</p>
                                  </div>
                                </div>
                                <div className="chat-list-right">
                                  <p className="small"></p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {/* end */}
            </div>
            <div className="end"></div>
          </div>
        </div>

        <div className="c-right">
          {open && (
            <div className="r-headers">
              {" "}
              <div className="head-left">
                <img
                  style={{ objectFit: "cover" }}
                  src={
                    user.id === fetchCreator.id
                      ? fetchParticipant.image
                      : fetchCreator.image || "/assests/images/user1.png"
                  }
                  alt=""
                />
                <span style={{ fontWeight: "bolder", fontSize: "large" }}>
                  {user.id === fetchCreator.id
                    ? fetchParticipant.userName
                    : fetchCreator.userName}
                </span>
                <div className="circle-c"></div>
              </div>
              <div className="head-right">
                <i className="fa-solid fa-magnifying-glass"></i>
                <i className="fa-solid fa-phone"></i>
                <i className="fa-solid fa-video"></i>
                <i className="fa-sharp fa-solid fa-list-ul"></i>
              </div>
            </div>
          )}

          {open !== true ? (
            <span
              style={{
                fontStyle: "italic",
                marginTop: "10rem",
              }}
              className="text-light fs-4 fw-bold  d-flex  justify-content-center "
            >
              please select a conversation
            </span>
          ) : (
            <>
              {" "}
              <div className="r-chat-box">
                {/* friend text */}
                {/* friend text */}
                {/* own text */}
                {/* own text */}

                {fetchmessage?.map((item) => (
                  <div key={item._id}>
                    {item.converid === conId && (
                      <div ref={scrollRef} key={item._id}>
                        {item?.sender?.id === user.id ? (
                          <OwnText
                            time={item?.createdAt}
                            text={item?.text}
                            userId={user.id}
                            creator={fetchCreator}
                            participant={fetchParticipant}
                          />
                        ) : (
                          <FriendText
                            time={item?.createdAt}
                            text={item?.text}
                            userId={user.id}
                            creator={fetchCreator}
                            participant={fetchParticipant}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="r-chat-input">
                <div className="input-left">
                  <InputEmoji
                    value={textMessage}
                    onChange={setTextMessage}
                    cleanOnEnter
                    onEnter={onSubmitHandler}
                    placeholder="Type a message"
                    theme="dark"
                    borderColor="#EAEAEA"
                  />
                  {/* <input
                onChange={(e) => setTextMessage(e.target.value)}
                type="text"
                placeholder="Enter Messages"
                name=""
                id=""
              /> */}
                </div>
                <div className="input-right">
                  <div className="enters">
                    <button onClick={onSubmitHandler}>
                      <i className="fa-solid fa-play"></i>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <UserModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
      />
      <ProfileModal
        show={profileShow}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </>
  );
};

export default ChatPage;

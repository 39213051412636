import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  Create_Conversation,
  Fetch_Conversation,
  Fetch_Users,
} from "../store/actions";
import { useToast } from "@chakra-ui/react";

const UserModal = ({ show, handleClose, handleShow }) => {
  const toast = useToast();

  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const { isError } = useSelector((state) => state.error);
  useEffect(() => {
    dispatch(Fetch_Users());
  }, [dispatch]);

  const onCreataConversation = (item) => {
    const recData = {
      id: item._id,
      userName: item.userName,
      email: item.email,
    };

    dispatch(Create_Conversation(recData));

    setTimeout(() => {
      dispatch(Fetch_Conversation());
    }, 500);
  };

  useEffect(() => {
    if (isError === "Sorry conversation already created") {
      toast({
        title: isError,
        description: "please try another user",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isError, toast]);

  return (
    <>
      <Modal
        className="mt-5"
        size="sm"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              {users.map((item) => (
                <div className="d-flex justify-content-between " key={item._id}>
                  <div className="carts mt-3 d-flex gap-2 align-items-center ">
                    <img src="/user1.png" alt="" />
                    <div className="">
                      <h4>{item.userName}</h4>
                      <p>{item.email}</p>
                    </div>
                  </div>
                  <button
                    onClick={() => onCreataConversation(item)}
                    className="btnss"
                  >
                    Add user
                  </button>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserModal;

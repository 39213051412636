export const messageReducer = (state = { message: [] }, action) => {
  switch (action.type) {
    case "FETCH_MESSAGE":
      return { ...state, message: action.payload };

    case "LOG_OUT":
      return { message: [] };

    default:
      return state;
  }
};

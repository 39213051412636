export const conversationReducer = (state = { conversation: [] }, action) => {
  switch (action.type) {
    case "FETCH_CONVER":
      return { ...state, conversation: action.payload };

    case "LOG_OUT":
      return { conversation: [] };

    default:
      return state;
  }
};

import { configureStore } from "@reduxjs/toolkit";
import { authReducer, updateUsersReducer, usersReducer } from "./authReducer";
import { conversationReducer } from "./conversationReducer";
import { errorReducer } from "./errorReducer";
import { messageReducer } from "./messageReducer";

const currentUser = localStorage.getItem("users")
  ? JSON.parse(localStorage.getItem("users"))
  : {};

const Initial_State = {
  auth: { user: currentUser },
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
    error: errorReducer,
    con: conversationReducer,
    msg: messageReducer,
    user: usersReducer,
    update: updateUsersReducer,
  },
  preloadedState: Initial_State,
});

const Initial_State = {
  isLoading: false,
  isError: null,
  status: null,
};

export const errorReducer = (state = Initial_State, action) => {
  switch (action.type) {
    case "IS_FETCHING":
      return { ...state, isLoading: true, isError: null };

    case "IS_SUCCESS":
      return { ...state, isLoading: false, isError: null };

    case "REG_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: null,
        status: action.payload,
      };

    case "LOG_OUT":
      return { ...state, isLoading: false, isError: null, status: null };

    case "IS_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
        status: null,
      };

    default:
      return state;
  }
};

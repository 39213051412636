export const authReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case "SIGN_UP":
      return { ...state, user: action.payload };
    case "SIGN_IN":
      return { ...state, user: action.payload };
    case "LOG_OUT":
      return { user: {} };

    default:
      return state;
  }
};

export const usersReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case "FETCH_USERS":
      return { ...state, users: action.payload };

    case "LOG_OUT":
      return { users: [] };

    default:
      return state;
  }
};

export const updateUsersReducer = (state = { updateuser: null }, action) => {
  switch (action.type) {
    case "UPDATE_USER":
      return { ...state, updateuser: action.payload };

    case "LOG_OUT":
      return { updateuser: null };

    default:
      return state;
  }
};

import React from "react";
import { useSelector } from "react-redux";
import {
  unstable_HistoryRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import history from "./history";
import AuthPage from "./pages/AuthPage";
import ChatPage from "./pages/ChatPage";

const App = () => {
  const { user } = useSelector((state) => state.auth);

  let myuser;

  if (user?.token) {
    myuser = (
      <Routes>
        <Route path="/chat" element={<ChatPage />} />
        <Route path="*" element={<Navigate to="/chat" replace />} />
      </Routes>
    );
  } else {
    myuser = (
      <Routes>
        <Route path="/" element={<AuthPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  return (
    <Router history={history}>
      <main>{myuser}</main>
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import onImageHandlers from "./OnImageHandler";
import { Spinner } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Update_User } from "../store/actions";

const ProfileModal = ({ show, handleClose, handleShow }) => {
  const { user } = useSelector((state) => state.auth);
  const { updateuser } = useSelector((state) => state.update);
  const { isLoading } = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  const toast = useToast();
  const [fileLoader, setFileLoader] = useState(false);
  const [input, setInput] = useState({
    userName: user.userName,
    email: user.email,
    oldPass: "",
    newPass: "",
  });

  const onFileHandler1 = async (event) => {
    const result = await onImageHandlers(event, setFileLoader);

    setFile(result);
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const recData = {
      userName: input.userName,
      email: input.email,
      oldPass: input.oldPass,
      newPass: input.newPass,
      image: file,
    };
    dispatch(Update_User(recData));
  };

  useEffect(() => {
    if (updateuser !== null) {
      toast({
        title: "Success",
        description: "User update successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateuser]);

  return (
    <>
      <Modal className="mt-5" size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3">
              <Form.Label>UserName</Form.Label>
              <Form.Control
                onChange={onChangeHandler}
                name="userName"
                defaultValue={input.userName}
                type="text"
                autoFocus
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                onChange={onChangeHandler}
                name="email"
                defaultValue={input.email}
                type="email"
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                onChange={onChangeHandler}
                name="oldPass"
                type="password"
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                onChange={onChangeHandler}
                name="newPass"
                type="password"
              />
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Upload Photo</Form.Label>
              <Form.Control
                onChange={(e) => onFileHandler1(e.target.files[0])}
                type="file"
                accept="image/*"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {fileLoader || isLoading ? <Spinner /> : "Save Changes"}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfileModal;

import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Create_User } from "../store/actions";
import { useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

const RegisterModal = ({ show, handleClose, handleShow }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [input, setInput] = useState({
    userName: "",
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const toast = useToast();

  const { status, isError, isLoading } = useSelector((state) => state.error);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const recData = {
      userName: input.userName,
      email: input.email,
      password: input.password,
    };
    dispatch(Create_User(recData));
    setIsLogin(true);
  };

  useEffect(() => {
    if (status === 200) {
      toast({
        title: "Account created successfully",
        description: "please login....",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [status, toast]);

  useEffect(() => {
    if (isError && isLogin) {
      toast({
        title: isError,
        description: "please try again",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [isError, toast, isLogin]);

  return (
    <>
      <Modal className="mt-5" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text1">Register here</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitHandler}>
            <Form.Group className="mb-3">
              <Form.Label>UserName</Form.Label>
              <Form.Control
                onChange={onChangeHandler}
                name="userName"
                required
                type="text"
                placeholder="type your username"
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                onChange={onChangeHandler}
                name="email"
                required
                type="email"
                placeholder="type your email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                onChange={onChangeHandler}
                name="password"
                required
                type="password"
                placeholder="type your password"
                autoFocus
              />
            </Form.Group>
            <Button type="submit" variant="primary">
              {isLoading ? <Spinner /> : "Register"}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegisterModal;

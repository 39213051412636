import history from "../../history";
import api from "../api";

export const Create_User = (recData) => async (dispatch) => {
  try {
    dispatch({ type: "IS_FETCHING" });

    await api.post("/api/user/signup", recData);
    dispatch({ type: "SIGN_UP", payload: "" });
    dispatch({ type: "REG_SUCCESS", payload: 200 });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const Sign_In = (recData) => async (dispatch) => {
  try {
    dispatch({ type: "IS_FETCHING" });

    const { data } = await api.post("/api/user/signin", recData);
    dispatch({ type: "SIGN_IN", payload: data });
    dispatch({ type: "IS_SUCCESS" });
    localStorage.setItem("users", JSON.stringify(data));
    history.push("/chat");
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};
export const Fetch_Users = () => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState().auth;
  try {
    dispatch({ type: "IS_FETCHING" });

    const { data } = await api.get(`/api/user`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({ type: "FETCH_USERS", payload: data });
    dispatch({ type: "IS_SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const Update_User = (recData) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState().auth;
  try {
    dispatch({ type: "IS_FETCHING" });

    const { data } = await api.patch(`/api/user/update`, recData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    dispatch({ type: "UPDATE_USER", payload: data });
    dispatch({ type: "IS_SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const Fetch_Conversation = () => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState().auth;
  try {
    dispatch({ type: "IS_FETCHING" });

    const { data } = await api.get("/api/con", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({ type: "FETCH_CONVER", payload: data });
    dispatch({ type: "IS_SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};
export const Create_Conversation = (data) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState().auth;
  try {
    dispatch({ type: "IS_FETCHING" });

    await api.post(`/api/con/create`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    dispatch({ type: "IS_SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const Fetch_Message = (id) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState().auth;
  try {
    dispatch({ type: "IS_FETCHING" });

    const { data } = await api.get(`/api/message/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    dispatch({ type: "FETCH_MESSAGE", payload: data });
    dispatch({ type: "IS_SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const Create_Message = (data) => async (dispatch, getState) => {
  const {
    user: { token },
  } = getState().auth;
  try {
    dispatch({ type: "IS_FETCHING" });

    await api.post(`/api/message/create`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    dispatch({ type: "IS_SUCCESS" });
  } catch (err) {
    dispatch({ type: "IS_ERROR", payload: err.response.data.message });
  }
};

export const Log_Out = () => {
  return {
    type: "LOG_OUT",
  };
};
